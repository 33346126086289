import { Component } from '@angular/core';

@Component({
  selector: 'app-most-recent-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonMostRecentComponent {

  public bannerSkeleton = {'margin-left': '-50vw', 'margin-right': '-50vw', 'min-width': '100vw',
    right: '50%', left: '50%', height: '75px', display: 'flex'
  };
  public smallCardSkeleton = {height: '200px', width: '100%'};
  public titleSkeleton = {height: '48px', 'margin-top': '32px', width: '35%'};
  public subtitleSkeleton = {height: '40px', 'margin-top': '16px', width: '65%'};
  public defaultMargin = {'margin-top': '48px'};
  public separatorSkeleton = {height: '15px', width: '50px', 'text-align': 'center', margin: '32px 0'};
  public buttonSkeleton = {height: '50px', width: '150px', 'margin-top': '64px', 'text-align': 'center'};
  public smallTextSkeleton = {width: '100px', 'margin-right': '24px', height: '40px'};
}
