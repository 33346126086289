import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';

import { RecentPostsComponent } from './recent-posts.component';
import { I18nModule } from '../../core/i18n/i18n.module';
import { CardModule } from '../../shared/card/card.module';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    RecentPostsComponent,
  ],
  imports: [
    BrowserModule,
    I18nModule,
    CardModule
  ],
  exports: [
    RecentPostsComponent
  ],
  providers: [DatePipe],
  bootstrap: []
})
export class RecentPostsModule implements DoBootstrap { 
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const mostrecent = createCustomElement(RecentPostsComponent, { injector: this.injector });
    customElements.define('blog-recent-posts', mostrecent);
  }
}