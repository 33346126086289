export const environment = {
  production: true,
  apiUrl: 'http://localhost:3000',
  env: 'prod',
  contentstack: {
    api_key: 'blt8c15ccb40d3f1444',
    delivery_token: 'cs66f253cd80dc3ad831fcdef4',
    environment: 'prod'
  },
  cloundfrontURL: 'https://microfrontend-blog-martech.cloud.itau.com.br/components',
  cookieLangName: 'locale'
};
