import { Component, Input } from '@angular/core';
import { AnalyticsService } from '../../core/services/analytics.service/analytics.service';
import { HelperService } from '../../core/services/helpers.service/helpers.service';


@Component({
  selector: 'blog-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent {
  @Input() tags: string;
  @Input() segment: string;
  items: string[];
  currentLanguage: string;
  constructor(
    private analytics: AnalyticsService,
    private readonly helper: HelperService,
  ) {}

  ngOnInit(): void {
    this.currentLanguage = this.helper.getLanguage();
    this.items = this.tags ? this.tags.split(','): [];
  }

  getUrl(tag: string): string {
    switch (this.currentLanguage) {
      case 'en-us':
        return `/${this.segment}/${this.currentLanguage}/search?t=${tag}`;
      case 'es-es':
        return `/${this.segment}/${this.currentLanguage}/buscador?t=${tag}`;
      default:
        return `/${this.segment}/busca?t=${tag}`;
    }
  }

  eventClick(tag: string) {
    this.analytics.trackEvent({
      type: 'LNK',
      component: 'TagList',
      text: tag,
      action: `${this.segment}:TagList`,
      detail: `lnk:${this.segment}:TagList:${tag}`,
    });
  }
}