import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IdsMainButtonModule } from '@ids/angular';

import { CardModule } from '../../shared/card/card.module';
import { I18nModule } from '../../core/i18n/i18n.module';

import { BookmarkListComponent } from './bookmark-list.component';

@NgModule({
  declarations: [
    BookmarkListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CardModule,
    I18nModule,
    IdsMainButtonModule
  ],
  exports: [
    BookmarkListComponent
  ],
  providers: [],
  bootstrap: []
})
export class BookmarkListModule implements DoBootstrap { 
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const bookmarkList = createCustomElement(BookmarkListComponent, { injector: this.injector });
    customElements.define('blog-bookmark-list', bookmarkList);
  }
}
