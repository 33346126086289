import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { HelperService } from '../helpers.service/helpers.service';

interface EventGA {
  type: string;
  component: string;
  title?: string;
  text?: string;
  action?: string;
  category?: string;
  detail?: string;
}

type ItauDigitalAnalyticsType = {
  track: (params: { analytics: AnalyticsProps }) => void;
}

type AnalyticsProps = {
  page: {
    nome: string;
    url: string;
  },
  custom?: {
    squadresponsaveltag?: string;
  };
  events: {
    category: string;
    action: string;
    label: string;
  },
  rule: string;
  event_name: string;
  parameters: {
    custom_path: string;
    implementation_team: string;
    detail: string;
  };
};

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private helper: HelperService
  ) {}

  trackEvent(eventGa: EventGA): void {
    const itauDigitalAnalytics: ItauDigitalAnalyticsType = window['ItauDigitalAnalytics' as keyof typeof window] || null;
    if (!itauDigitalAnalytics) { return; }

    const { type, component, title, text, action, category, detail } = eventGa;
    let label = `${type}:${component}`;
    let pageNameGA4 = this.document.location.pathname.substring(0, 100).toLocaleLowerCase();
    const detailGA4 = this.helper.skewerCase(detail as string);
    
    if (title) { label = `${label}:${title}`; }
    if (text) { label = `${label}:${this.helper.clearSpecialChars(text)}`; }

    const analytics = {
      page: {
        nome: `IT:NL:IN:BLOG:${this.formatPageName()}`,
        url: this.document.location.href,
      },
      custom: {
        squadresponsaveltag: 'Martech/Produtos'
      },
      events: {
        category: category || 'Clique',
        action: action || 'objeto clicado',
        label: this.helper.pascalCase(label),
      },
      rule: 'customLink',
      event_name: 'click',
      parameters: {
        custom_path: pageNameGA4,
        implementation_team: 'produtos-martech:martech',
        detail: detailGA4,
      }
    };
    itauDigitalAnalytics.track({ analytics });
  }

  formatPageName(): string {
    const path = this.document.location.pathname.split('/').filter(Boolean);
    const pageName = path.map((item) => {
      return item.split('-').map((innerItem) => innerItem.charAt(0).toUpperCase() + innerItem.slice(1)).join('');
    }).join(":");

    return pageName;
  }
}
