import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { createCustomElement } from '@angular/elements';

import { SearchComponent } from './search.component';
import { I18nModule } from '../../core/i18n/i18n.module';
import { IdsIconModule, IdsInputModule, IdsInputSearchModule } from '@ids/angular';

@NgModule({
  declarations: [
    SearchComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    IdsIconModule,
    IdsInputModule,
    IdsInputSearchModule
  ],
  exports: [
    SearchComponent
  ],
  providers: [],
  bootstrap: []
})
export class SearchModule implements DoBootstrap { 
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const search = createCustomElement(SearchComponent, { injector: this.injector });
    customElements.define('blog-search', search);
  }
}
