import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { I18nModule } from './../../core/i18n/i18n.module';

import { BookmarkArticleModule } from '../../components/bookmark-article/bookmark-article.module';
import { CardComponent } from './card.component';


import localePT from '@angular/common/locales/pt';
import localeES from '@angular/common/locales/es';

registerLocaleData(localePT);
registerLocaleData(localeES);

@NgModule({
  imports: [CommonModule, BookmarkArticleModule, I18nModule],
  declarations: [CardComponent],
  exports: [CardComponent]
})
export class CardModule {
}
