<div class="menu-categories">
  <div class="ids-container">
    <div class="mobile-title hidden-sm hidden-md hidden-lg ids-mt-9">
      <span>{{ "CATEGORIES.TITLE" | translate }}</span>
    </div>

    <div class="menu-categories__buttons">
      <h2 class="title hidden-sm hidden-md hidden-lg ids-mt-2 ids-mb-7">{{ "CATEGORIES.MOBILE_TITLE" | translate }}</h2>
      <ul class="menu-categories__list" role="list" #menuCategories>
        <li class="menu-categories__item" role="listitem"
          [ngClass]="{'menu-categories__item--active': isActive(categoryDefault.uid)}"
        >
          <span>
            <a tabindex="0" role="button" class="menu-categories__link"
              [attr.aria-pressed]="isActive(categoryDefault.uid)"
              (click)="setCategory({event: $event, category: categoryDefault})"
              (keyup.enter)="setCategory({event: $event, category: categoryDefault})"
            >
              {{categoryDefault.title}}
            </a>
          </span>
        </li>
        <ng-container *ngIf="categories.length">
          <li class="menu-categories__item" role="listitem" 
            *ngFor="let category of categories"
            [ngClass]="{'menu-categories__item--active': isActive(category.uid)}"
          >
            <a role="button" tabindex="0" class="menu-categories__link"
              [attr.aria-pressed]="isActive(category.uid)"
              (click)="setCategory({event: $event, category})"
              (keyup.enter)="setCategory({event: $event, category})"
            >
              {{ category.title }}
            </a>
          </li>
        </ng-container>
      </ul>
      <div role="alert" class="screen-reader-feedback">
        <span aria-live="polite" aria-atomic="true" *ngIf="clicked">Filtrando artigos pela categoria {{ categorySelectedName }}</span>
      </div>
    </div>
  </div>
</div>