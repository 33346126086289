import { Component, EventEmitter, Output, Input, OnInit, AfterViewInit} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LikertOptionKey, Language, OPTIONS_BY_LANGUAGE } from '@ids/tools';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../core/services/analytics.service/analytics.service';

export interface StorageLikert {
  id_post: string;
  emotion: string;
}

export interface StorageRating {
  id_post: string;
  rating: number;
}

@Component({
  selector: 'blog-likert',
  templateUrl: './likert.component.html'
})
export class LikertComponent implements OnInit, AfterViewInit {
  @Output() change = new EventEmitter<string>();
  @Input() value: LikertOptionKey;
  @Input() idPost: string = '';
  @Input() segment: string = '';
  lang: Language;
  form: FormGroup;
  private _options: any = {};
  private likertInfos: StorageLikert[] = JSON.parse(String(window.localStorage.getItem('postLikert'))) || [];

  constructor(
    private readonly translate: TranslateService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.lang = this.translate.currentLang as Language || 'pt';

    this.form = new FormGroup({
      assessment: new FormControl<LikertOptionKey | ''>(this.getLikertValue())
    });
    this.getLikertOptionsByLang();
  }

  ngAfterViewInit() {
    this.form?.get('assessment')?.valueChanges.subscribe(this.saveLikertSelection.bind(this));
  }

  saveLikertSelection(emotion: string) {
    const index = this.likertInfos.findIndex(item => item.id_post === this.idPost);
    if(index > -1) {
      this.likertInfos[index].emotion = emotion;
    } else {
      this.likertInfos.push({
        id_post: this.idPost,
        emotion
      })
    }
    window.localStorage.setItem('postLikert', JSON.stringify(this.likertInfos));
    this.sendAnalyticsEvent(this._options[emotion]);
  }

   getLikertOptionsByLang(): void {
    (OPTIONS_BY_LANGUAGE[this.lang]).forEach(
      (option) => {
        this._options[option.key] = option.value;
      },
    );
  }

  sendAnalyticsEvent(emotion: string | string[]): void {
    let title = emotion;
    if (Array.isArray(title)) { title = title[0]; }

    this.analyticsService.trackEvent({
      component: 'Likert',
      type: 'BTN',
      action: `${this.segment}:Likert`,
      title,
      detail: `btn:${this.segment}:Likert:${title}`,
    });
  }

  private getLikertValue(): LikertOptionKey | '' {
    if (this.likertInfos.length) {
      const indexResponseLikert = this.likertInfos.findIndex((item: StorageLikert) => item.id_post === this.idPost);
      if (indexResponseLikert > -1) {
        return this.likertInfos[indexResponseLikert].emotion as LikertOptionKey;
      }
    } 

    const storageRating = JSON.parse(String(localStorage.getItem('postRating'))) || [];
    if (storageRating.length) {
      const indexResponseRating = storageRating.findIndex((item: StorageRating) => item.id_post === this.idPost);
      
      if (indexResponseRating > -1) {
        const ratingToLikert = ['veryBad', 'bad', 'okay', 'good', 'veryGood'];
        return ratingToLikert[storageRating[indexResponseRating].rating - 1] as LikertOptionKey;
      }
    }

    return '';
  }
};