import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '../../core/services/analytics.service/analytics.service';
import { ToastData } from '../../core/toast/toast-config';
import { ToastService } from '../../core/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bookmark-article',
  templateUrl: './bookmark-article.component.html',
  styleUrls: ['./bookmark-article.component.scss']
})
export class BookmarkArticleComponent implements OnInit {
  @Input() articleUid: string = '';
  @Input() articleTitle: string = '';
  @Input() homeHub: boolean = false;

  @Input() type: 'short' | 'long' = 'short';
  @Input() segment: string = '';
  
  public isOn = false;
  public key = 'blog_bookmarks_';
  private bookmarks: any[] = [];
  private readonly toastData: ToastData = {
    duration: 5000,
    text: '',
    type: 'success',
    icon: ''
  };

  constructor(
    private toastService: ToastService,
    private analytics: AnalyticsService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.key += this.segment;
    this.bookmarks = this.getBookmarks();

    if (this.bookmarks.indexOf(this.articleUid) !== -1) {
      this.isOn = true;
    }
  }

  public getLabel(article = '', checked = false): string {
    const ariaLabel = checked ? 'BOOKMARK_ARTICLE.ARTICLE_SAVED' : 'BOOKMARK_ARTICLE.SAVE_TO_AFTER' ;
    return this.translate.instant(ariaLabel, { article });
  }

  public onClick(): void {
    this.bookmarks = this.getBookmarks();

    if (this.bookmarks.indexOf(this.articleUid) === -1) {
      this.saveBookmark();
    } else {
      this.removeBookmark();
    }
    this.toastService.show(this.toastData);
    this.sendAnalyticsEvent();

    window.localStorage.setItem(this.key, JSON.stringify(this.bookmarks));
  }

  public getBookmarks() {
    const bookmarks = [];
    if (localStorage.hasOwnProperty(this.key)) {
      bookmarks.push(...JSON.parse(String(localStorage.getItem(this.key))));
    }

    return bookmarks;
  }

  private saveBookmark() {
    this.bookmarks.push(this.articleUid);
    this.isOn = true;

    this.toastData.text = this.translate.instant('BOOKMARK_ARTICLE.TOAST_ADD');
  }

  private removeBookmark() {
    this.bookmarks.splice(this.bookmarks.indexOf(this.articleUid), 1);
    this.isOn = false;
    this.toastData.text = this.translate.instant('BOOKMARK_ARTICLE.TOAST_REMOVE');
  }

  public sendAnalyticsEvent() {
    const component = 'BookMarkArticle';
    this.analytics.trackEvent({
      type: 'BTN',
      component,
      title: this.articleTitle, 
      text: this.isOn ? 'ArtigoSalvo' : 'ArtigoExcluido',
      action: `${this.segment}:${component}`,
      detail: `btn:${this.segment}:${component}:${this.articleTitle}:${this.isOn ? 'ArtigoSalvo': 'ArtigoExcluido'}`,
    });
  }
}
