import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { HelperService } from "../../core/services/helpers.service/helpers.service";
import { AnalyticsService } from "../../core/services/analytics.service/analytics.service";

@Component({
  selector: 'app-card',
  templateUrl: 'card.component.html',
  styleUrls: ['card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {
  @Input() article: any;
  @Input() cssClass: string = '';
  @Input() segment: string;
  @Input() analyticsComponent: string;

  currentLanguage: string;
  categories: any[] = [];

  constructor(
    private readonly helpers: HelperService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.currentLanguage = this.helpers.getLanguage();
    if (this.article) {
      this.categories = this.formatCategories(this.article.baas_categories);
    }
  }

  public get articleImage(): string {
    if (!this.article.page_properties || !this.article.page_properties.thumb_image) {
      return '';
    }
    return this.article.page_properties.thumb_image.path;
  }

  public sendAnalyticsEvent(): void  {
    const action = `${this.segment}:${this.analyticsComponent}`;
    const title = this.helpers.clearSpecialChars(this.article.title)
    this.analyticsService.trackEvent({
      component: this.analyticsComponent,
      action,
      type: 'BTN',
      title: this.article.title,
      detail: `btn:${this.segment}:${this.analyticsComponent}:${title}`,
    });
  }

  public formatText(text: string, size=100): string {
    return this.helpers.cropText(text, size);
  }

  private formatCategories(categories: any[]) {
    return categories.map(category => ({
      uid: category.uid,
      title: this.formatText(category.title, 50)
    }));
  }
}