import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IdsMainButtonModule } from '@ids/angular';

import { CardModule } from '../../shared/card/card.module';
import { I18nModule } from '../../core/i18n/i18n.module';
import { SearchListComponent } from './search-list.component';

@NgModule({
  declarations: [
    SearchListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CardModule,
    I18nModule,
    IdsMainButtonModule
  ],
  exports: [
    SearchListComponent
  ],
  providers: [],
  bootstrap: []
})
export class SearchListModule implements DoBootstrap { 
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const searchList = createCustomElement(SearchListComponent, { injector: this.injector });
    customElements.define('blog-search-list', searchList);
  }
}
