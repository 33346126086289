import { Injectable, Injector, Inject, StaticProvider } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ToastComponent } from './toast.component';
import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig, defaultToastConfig } from './toast-config';
import { ToastRef } from './toast-ref';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private lastToast: ToastRef | undefined;

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
  ) {
    if (!this.toastConfig.position) {
      this.toastConfig.position = defaultToastConfig.position;
    }
  }

  show(data: ToastData) {
    if (this.lastToast !== undefined && this.lastToast.isVisible()) {
      this.lastToast.close();
      this.lastToast = undefined;
    }

    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy });

    const toastRef = new ToastRef(overlayRef);
    this.lastToast = toastRef;

    const injector = this.getInjector(data, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);

    overlayRef.attach(toastPortal);

    return toastRef;
  }

  getPositionStrategy() {
    const position = this.toastConfig.position ? this.toastConfig.position : defaultToastConfig.position;
    return this.overlay.position()
      .global()
      .centerHorizontally()
      .top(`${position?.top || 0}vh`);
  }

  getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector): Injector {
    const providers: StaticProvider[] = [
      { provide: ToastData, useValue: data },
      { provide: ToastRef, useValue: toastRef }
    ];
    return Injector.create({ providers, parent: parentInjector });
  }
}