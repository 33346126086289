import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuCategoriesComponent } from './menu-categories.component';
import { I18nModule } from './../../core/i18n/i18n.module';

@NgModule({
  imports: [CommonModule, I18nModule],
  declarations: [MenuCategoriesComponent],
  exports: [MenuCategoriesComponent]
})
export class MenuCategoriesModule {
}
