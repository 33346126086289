import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from "ngx-translate-messageformat-compiler";
import { environment } from "./../../../environments/environment";

function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`) || [];
  if (parts.length === 2) return parts.pop()?.split(';').shift();
}

function cookieLang(): string {
  const langName = getCookie(environment.cookieLangName)?.toLowerCase().substring(0, 2) || 'pt';
  return langName;
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  const domain = window.location.hostname.replace('www.', '');
  const port = window.location.port

  if (domain === 'localhost' && port === '4200') {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }

  return new TranslateHttpLoader(http, `${environment.cloundfrontURL}/app/assets/i18n/`, '.json');
}

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: cookieLang(),
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    })
  ],
  exports: [
    TranslateModule
  ]
})
export class I18nModule {
  private readonly lang = cookieLang();
  constructor (private readonly translate: TranslateService) {
    this.translate.use(this.lang);
  }
}