import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { I18nModule } from './../../core/i18n/i18n.module';
import { TagListComponent } from './tag-list.component';

@NgModule({
  declarations: [
    TagListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    I18nModule
  ],
  exports: [
    TagListComponent
  ],
  providers: [],
  bootstrap: []
})
export class TagListModule implements DoBootstrap { 
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const tagList = createCustomElement(TagListComponent, { injector: this.injector });
    customElements.define('blog-tag-list', tagList);
  }
}
