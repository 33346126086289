import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentstackService } from '../../core/services/contentstack/contentstack.service';

@Component({
  selector: 'blog-recent-posts',
  templateUrl: './recent-posts.component.html',
  styleUrls: ['./recent-posts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecentPostsComponent implements OnInit {
  @Input() segment = '';
  public blogs: any[] = [];
  public articles: any[] = [];
  public loading = false;
  public filteredArticles: any[] = [];

  constructor(
    private readonly service: ContentstackService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getAllBlogs();
  }

  getAllBlogs() {
    const requests: any[] = [];
    this.service.getAllBlogs().subscribe((res: any) => {
      res[0].forEach((blog: any) => {
        if (blog.segment !== 'dev' && blog.segment !== 'treinamento' && blog.segment !== 'institucional' && blog.segment !== 'artigos') {
          this.blogs.push(blog.segment);
          requests.push(this.loadArticles(blog.segment));
        }
      });
      Promise.all(requests).then(() => {
        this.filteredArticles.sort((a: any, b: any) => new Date(b.publication_date).getTime() - new Date(a.publication_date).getTime());
      });
    });
  }

  loadArticles(blog: string) {
    return new Promise((resolve) => {
      this.service.articlesList(blog).subscribe((res: any) => {
        this.articles = [...this.articles, ...res[0]];

        const currentDate = new Date();
        const fifteenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 15));
        const date = this.datePipe.transform(fifteenDaysAgo, 'yyyy-MM-dd');

        this.filterArticlesByDate(date);

        this.articles.sort((a: any, b: any) => new Date(b.publication_date).getTime() - new Date(a.publication_date).getTime());

        const articlesList: any[] = this.buildArticleList();

        const articlesData = this.articles.filter((article: any) => {
          return !articlesList.find((item: any) => item.uid === article.uid);
        });

        this.filteredArticles = [...articlesList, ...articlesData.slice(0, 5 - articlesList.length)];
        resolve(this.filteredArticles);
      });
    });
  }

  private buildArticleList() {
    const articlesList: any[] = [];

    const blogs = new Set();
    for (const article of this.articles) {
      if (articlesList.length >= 5) {
        break;
      }

      if (!blogs.has(article.template_selection[0].segment)) {
        articlesList.push(article);
        blogs.add(article.template_selection[0].segment);
      }
    }
    return articlesList;
  }

  private filterArticlesByDate(date: string | null) {
    this.articles = this.articles.filter((article: any) => {
      return date && article.publication_date >= date;
    });
  }
}