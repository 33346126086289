<div class="blog-home">
  <app-menu-categories [segment]="segment" (clickEvent)="filterByCategory($event)"></app-menu-categories>

  <div *ngIf="!loading">
    <app-featured-post [article]="featuredArticle" [segment]="segment" *ngIf="featuredArticle"></app-featured-post>
    <div class="ids-container ids-pb-6">
      <app-post-list [items]="items" [segment]="segment" *ngIf="items.length"></app-post-list>
    </div>
  </div>
  <ng-container *ngIf="loading">
      <app-featured-post-skeleton></app-featured-post-skeleton>
  </ng-container>
</div>