import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, Optional, QueryList, ViewChildren } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { catchError, debounceTime, fromEvent, map, Observable, of, switchMap, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../core/services/helpers.service/helpers.service';
import { ContentstackService } from '../../core/services/contentstack/contentstack.service';
import { AnalyticsService } from '../../core/services/analytics.service/analytics.service';

export interface SearchResult {
  articles: { entries: any[], total: number };
  tags: string[];
}

@Component({
  selector: 'blog-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {
  @Input() segment: string = '';
  @Input() hasMenuLanguage = false;
  @ViewChildren("closeModalButton") closeModalButton: QueryList<ElementRef>;
  @ViewChildren("buttonOpenModal") buttonOpenModal: QueryList<ElementRef>;

  isMobile: boolean = false;
  public searchForm: UntypedFormGroup;
  public searchResult: SearchResult | null = null;
  public show: boolean = false;
  public isSearching: boolean = false;
  public showLTMessage: boolean = false;

  private document: Document;
  public term: FormControl = new FormControl();

  constructor(
    @Optional() @Inject(DOCUMENT) document: any,
    private helpers: HelperService,
    private contentStackService: ContentstackService,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService
  ) {
    this.document = document;
  }

  ngOnInit() {
    this.term.valueChanges.pipe(
      tap(() => {
        this.searchResult = null;
        if (!this.isMobile) {
          this.closeModal();
        }
      }),
      debounceTime(500),
      switchMap(this.searchArticles.bind(this)),
      map(this.formatSearchResult.bind(this))
    ).subscribe();

    fromEvent(window, 'resize')
      .subscribe(() => this.setIsMobile(window));

    fromEvent(window, 'load')
      .subscribe(() => this.setIsMobile(window));
  }

  setIsMobile(window: Window) {
    if (window.innerWidth < 768) {
      this.isMobile = true;
      return true;
    }

    this.isMobile = false;
    return false;
  }

  ngAfterViewInit(): void {
    this.closeModalButton.changes.subscribe(() => {
      if (this.isMobile && this.closeModalButton.length > 0) {
        this.closeModalButton.first.nativeElement.focus();
      }
    });
  }

  public boldString(str: string): string {
    const result = str.trim().toLowerCase();
    return `<b>${result}</b>`;
  }

  public cropText(text: string) {
    return this.helpers.cropText(text, 80);
  }

  public showModal(): void {
    window.scrollTo(0, 0);
    this.show = true;
    this.document.body.style.overflow = 'hidden';
    this.hideAllElementsOfReader();
  }

  public closeModal(): void {
    this.show = false;
    this.searchResult = null;
    this.document.body.removeAttribute('style');
    this.showAllElementsOfReader();

    if (!this.isMobile) {
      this.document.getElementById('term')?.focus();
    } else {
      this.term.setValue('', { onlySelf: true, emitEvent: false });
      if (this.buttonOpenModal.first) {
        this.buttonOpenModal.first.nativeElement.focus();
      }
    }
  }

  public searchByEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.isValidTerm()) {
      event.preventDefault();
      const value = this.term.value;

      this.closeModal();
      this.analyticsService.trackEvent({
        type: 'BTN',
        component: 'NavigationMenu',
        title: 'Busca',
        text: value,
        action: `${this.segment}/Header`,
        detail: `btn:${this.segment}:header:busca:${value}`,
      });
      setTimeout(() => {
        window.location.href = `${this.getSearchUrl()}?s=${value}`;
      }, 500);
    }
  }

  getSearchUrl(): string {
    const currentLang = this.translateService.currentLang;
    const languages: { pt: string, en: string, es: string } = { pt: '/busca', en: '/en-us/search', es: '/es-es/buscador' };
    
    if(this.segment === 'institucional') {
      return `${languages[currentLang as keyof typeof languages]}`;
    }

    return `/${this.segment + languages[currentLang as keyof typeof languages]}`;
  }

  private hideAllElementsOfReader(): void {
    const searchElementsToNotHide = ':not(.container):not(.container-search-bookmark):not(blog-search):not(blog-search .search__container):not(blog-search .search__container form):not(blog-search .search__container form *):not(blog-search .search__container .search__modal):not(blog-search .search__container .search__modal *):not(blog-search .search__container .search__loading):not(blog-search .search__container .search__loading *)';
    const headerElements = Array.from(this.document.querySelectorAll(`header *:not([aria-hidden="true"]):not([tabindex="-1"])${searchElementsToNotHide}`));
    const mainElements = Array.from(this.document.querySelectorAll('main *:not([aria-hidden="true"]):not([tabindex="-1"])'));
    const footerElements = Array.from(this.document.querySelectorAll('footer.footer *:not([aria-hidden="true"]):not([tabindex="-1"])'));
    const allElements = [...headerElements, ...mainElements, ...footerElements];

    for (const element of allElements) {
      element.setAttribute('aria-hidden', 'true');
      element.setAttribute('tabindex', '-1');
      element.classList.add('search__aria-hidden');
    }
  }

  private showAllElementsOfReader(): void {
    const allElements = Array.from(this.document.querySelectorAll('.search__aria-hidden'));
    for (const element of allElements) {
      element.removeAttribute('aria-hidden');
      element.removeAttribute('tabindex');
      element.classList.remove('search__aria-hidden');
    }
  }

  private isValidTerm(): boolean {
    return Boolean(this.term.value && this.term.value.length >= 3);
  }

  private searchArticles(term: string): Observable<any> {
    if (!this.isValidTerm()) { return of(null); }
    this.isSearching = true;
    return this.contentStackService.search(this.segment, term)
      .pipe(
        catchError(() => of([[], 0]))
      )
  }

  private getTags(entries: any[]): string[] {
    if (!entries || !entries.length) { return []; }

    const result: string[] = [];
    const term = this.term.value.toLowerCase();

    for (const entry of entries) {
      if (entry?.article_tags?.container_tags.length) {
        const tags = entry.article_tags.container_tags.filter((item: string) => item.toLowerCase().indexOf(term) > -1);
        result.push(...tags);
      }
    }

    return result.filter((item, index) => {
      return result.indexOf(item) === index;
    });
  }

  private formatSearchResult(data: any): void {
    if (data) {
      this.searchResult = {
        articles: { entries: data[0], total: Number(data[1]) },
        tags: this.getTags(data[0])
      };
      this.isSearching = false;
      this.showLTMessage = true;

      setTimeout(() => {
        this.showModal();
        this.showLTMessage = false;
      }, 1000);
    }
  }
}
