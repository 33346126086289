<div class="post-list" *ngIf="articles.length">
  <div class="post-list__header">
    <h2 class="post-list__overwrite">{{ 'MOST_RECENT_TITLE' | translate }}</h2>
  </div>

  <div class="post-list__list" #articlesMostRecent>
    <div class="post-list__first-row">
      <ng-container *ngFor="let item of articles.slice(0,2); let isFirst = first;">
        <app-card 
          [homeHub]="false"
          [authorBlogLink]="false"
          [ngClass]="{'first': isFirst}"
          [article]="item"
          [segment]="segment"
          [analyticsComponent]="analyticsComponent">
        </app-card>
      </ng-container>
    </div>

    <div class="post-list__second-row" *ngIf="articles.length > 2">
      <ng-container *ngFor="let item of articles.slice(2, articles.length); let isFirst = first;">
        <app-card 
          [homeHub]="false"
          [authorBlogLink]="false"
          [ngClass]="{'first': isFirst}"
          [article]="item"
          [segment]="segment"
          [analyticsComponent]="analyticsComponent">
        </app-card>
      </ng-container>
    </div>
  </div>

  <div class="post-list__seeAll">
    <a [href]="getMostRecentURL()" (click)="sendAnalyticsEvent()"
      [attr.aria-label]="'SEE_ALL_ARTICLES' | translate">
      {{ "SEE_ALL" | translate }} <i class="ids-icon ids-icon--small" aria-hidden="true">seta_direita</i>
    </a>
  </div>
</div>