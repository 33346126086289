import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { IdsMainButtonModule } from '@ids/angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { CardModule } from '../../shared/card/card.module';
import { MenuCategoriesModule } from '../../shared/menu-categories/menu-categories.module';
import { SkeletonMostRecentComponent } from './components/skeleton/skeleton.component';
import { I18nModule } from './../../core/i18n/i18n.module';

import { MostRecentComponent } from './most-recent.component';

@NgModule({
  declarations: [
    MostRecentComponent,
    SkeletonMostRecentComponent
  ],
  imports: [
    BrowserModule,
    MenuCategoriesModule,
    CardModule,
    NgxSkeletonLoaderModule,
    I18nModule,
    IdsMainButtonModule
  ],
  exports: [
    MostRecentComponent
  ],
  providers: [],
  bootstrap: []
})
export class MostRecentModule implements DoBootstrap { 
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const mostrecent = createCustomElement(MostRecentComponent, { injector: this.injector });
    customElements.define('blog-most-recent', mostrecent);
  }
}