<section class="container">
  <h2 class="articles__title">{{ title }}</h2>
  <p class="hidden-sm hidden-md hidden-lg">{{ subtitle }}</p>

  <ul class="articles__list" role="list" >
    <li class="articles__item" role="listitem" #articleItem *ngFor="let item of items">
      <app-card [homeHub]="false" [authorBlogLink]="false" [article]="item" [segment]="segment" [analyticsComponent]="'SearchListMicrofront'"></app-card>
    </li>
  </ul>

  <button
    idsMainButton
    *ngIf="showLoadMore()"
    [disabled]="isLoading"
    (click)="loadMore()" 
    class="load-more"
    role="button"
    aria-label="carregar mais artigos"
  >{{ 'LOAD_MORE' | translate }}</button>

  <div role="alert" class="screen-reader-feedback">
    <ng-container *ngIf="items.length && loadMoreClicked">
      <span aria-live="polite" aria-atomic="true">{{ 'SHOW_ITEMS_MESSAGE' | translate:{show: items.length, total: pagination.count} }}</span>
    </ng-container>
  </div>
</section>