import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { createCustomElement } from '@angular/elements';
import { IdsLikertModule } from '@ids/angular';

import { I18nModule } from './../../core/i18n/i18n.module';
import { LikertComponent } from './likert.component';

@NgModule({
    declarations: [
      LikertComponent,
    ],
    imports: [
        I18nModule,
        BrowserModule,
        IdsLikertModule,
        ReactiveFormsModule,
        FormsModule
    ],
    exports: [
      CommonModule,
      LikertComponent
    ],
    providers: [],
    bootstrap: []
  })
  export class LikertModule implements DoBootstrap { 
    constructor(private injector: Injector) {}
  
    ngDoBootstrap(): void {
      const likert = createCustomElement(LikertComponent, { injector: this.injector });
      customElements.define('blog-likert', likert);
    }
  }