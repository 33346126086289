import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum languages {
  pt = 'pt-br',
  en = 'en-us',
  es = 'es-es'
};

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    private readonly translate: TranslateService
  ) {}

  replaceAccents(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }

  cropText(text: string, limit: number): string {
    return text && text.length > limit ? text.slice(0, limit) + ' [...]' : text;
  }

  pascalCase(str: string){
    const fc = this.replaceAccents;
    return str.split(':').map((c) => {
      c = fc(c);
      return c.charAt(0).toUpperCase() + c.replace(/\s(.)/g, (a) => {
        return a.toUpperCase();
      }).replace(/\s/g, '').replace(/^(.)/, (b) => {
        return b.toLowerCase();
      }).slice(1);
    }).join(':');
  }

  getLanguage(): languages {
    const lang = this.translate.currentLang;
    return languages[lang as keyof typeof languages] || languages.pt;
  }

  /**
   * @param text @type string
   * @returns string
   * @description Método que transforma strings em skewer-case
   */
  skewerCase(text: string): string {
    return this.replaceAccents(text)
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .toLowerCase()
      .replace(/^-/, '')
      .replace(/\s+/g, '-');
  }

  /**
   * @description Remove caracteres especiais (acentuações, barras, etc).
   * @param text 
   * @returns string
   */
  clearSpecialChars(text: string): string {
    return text.replace(/[.,\/#!$%\^&\*;:{}=\_`~()?]/g, '');
  }
}
