import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ContentstackService, LIMIT } from '../../core/services/contentstack/contentstack.service';

@Component({
  selector: 'blog-bookmark-list',
  templateUrl: './bookmark-list.component.html',
  styleUrls: ['./bookmark-list.component.scss']
})
export class BookmarkListComponent implements OnInit {
  @Input() segment: string;
  public items: any[] = [];
  public loading: boolean = true;
  public pagination = {
    skip: 0,
    limit: LIMIT,
    count: 0
  };

  constructor(
    private readonly service: ContentstackService
  ) {}

  ngOnInit() {
    this.loadArticles();
  }

  @ViewChild('articleItem') set articleItem(_: Element) {
    this.setFocusArticle();
  }

  loadMore(): void {
    this.loadArticles();
  }

  showLoadMore(): boolean {
    return this.items.length < this.pagination.count;
  }

  get fullUrl(): string {
    return environment.cloundfrontURL;
  }

  private loadArticles(): void {
    const bookmarks = JSON.parse(String(window.localStorage.getItem(`blog_bookmarks_${this.segment}`))) || [];
    
    if (!bookmarks.length) {
      this.loading = false;

      return;
    }

    this.service.bookmarks(this.segment, bookmarks, this.pagination.skip)
      .subscribe(this.setItemsAndPagination.bind(this))
      .add(() => this.loading = false);
  }

  private setItemsAndPagination(response: any[]) {
    this.items.push(...response[0]);
    this.pagination.skip += LIMIT;
    this.pagination.count = response[1]
  }

  private setFocusArticle(): void {
    if (this.loading) { return; }

    if (this.pagination.skip > this.pagination.limit) {
      const currentItem = (this.pagination.skip - this.pagination.limit) + 1;
      const itemToFocus = (
        document.querySelector('.articles__item:nth-child(' + currentItem + ') .mfe-card__link')
      ) as HTMLElement;
      itemToFocus.focus();
      window.scrollTo({ top: itemToFocus.offsetTop });
    }
  }
}