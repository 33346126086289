import { CommonModule } from '@angular/common';
import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { I18nModule } from '../../core/i18n/i18n.module';

import { CardModule } from '../../shared/card/card.module';
import { MenuCategoriesModule } from '../../shared/menu-categories/menu-categories.module';
import { BookmarkArticleModule } from '../bookmark-article/bookmark-article.module';

import { FeaturedPostComponent } from './components/featured-post/featured-post.component';
import { SkeletonFeaturedPostComponent } from './components/featured-post/skeleton/skeleton.component';
import { HomeComponent } from './home.component';
import { PostListModule } from './components/post-list/post-list.module';

@NgModule({
  declarations: [
    HomeComponent,
    FeaturedPostComponent,
    SkeletonFeaturedPostComponent
  ],
  imports: [
    CommonModule,
    BookmarkArticleModule,
    MenuCategoriesModule,
    CardModule,
    PostListModule,
    NgxSkeletonLoaderModule,
    I18nModule
  ],
  exports: [
    HomeComponent
  ],
  providers: [],
  bootstrap: []
})
export class HomeModule implements DoBootstrap { 
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const Home = createCustomElement(HomeComponent, { injector: this.injector });
    customElements.define('blog-home', Home);
  }
}
