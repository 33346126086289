<section role="region" aria-label="Main Banner" class="mfe-featured-post" *ngIf="article">
  <div class="ids-container">  
  <h2 class="mfe-featured-post__title"><a [href]="article.url">{{ cropText(article.title, 100)}}</a></h2>
    <p class="mfe-featured-post__subtitle">{{ cropText(article.description || '', 200) }}</p>
  </div>
  <div class="mfe-featured-post__image">
    <img aria-hidden="true"
    [src]="articleImage"
    [alt]="'CARD.IMAGE' | translate">
  </div>
</section>