import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from '../../core/toast/toast.module';
import { I18nModule } from '../../core/i18n/i18n.module';
import { BookmarkArticleComponent } from './bookmark-article.component';

@NgModule({
  declarations: [
    BookmarkArticleComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    I18nModule,
    ToastModule.forRoot()
  ],
  exports: [
    BookmarkArticleComponent
  ],
  providers: [],
  bootstrap: []
})
export class BookmarkArticleModule implements DoBootstrap { 
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const bookmarkArticle = createCustomElement(BookmarkArticleComponent, { injector: this.injector });
    customElements.define('blog-bookmark-article', bookmarkArticle);
  }
}
