<section class="ids-card mfe-card__container card" *ngIf="article">
  <div class="mfe-card__header">
    <img aria-hidden="true" class="mfe-card__img"
      [src]="articleImage"
      [alt]="'CARD.IMAGE' | translate">
  </div>
  <div class="mfe-card__contentAndFooter">
    <div class="mfe-card__content">
      <span class="mfe-card__category" *ngIf="categories.length">{{categories[0].title}}</span>
      <h3 class="mfe-card__title">
        <a class="mfe-card__link" (click)="sendAnalyticsEvent()" [href]="article.url" [target]="homeHub ? '_blank' : '_self'"> {{ formatText(article.title) }} </a>
      </h3>
    </div>
    <div class="mfe-card__footer">
      <time [attr.datetime]="article.publication_date | date:'dd-M-YYYY'">{{ article.publication_date | date:'dd MMM YYYY':'':currentLanguage }}</time>
      <app-bookmark-article [segment]="segment" [articleUid]="article.uid" [articleTitle]="article.title"></app-bookmark-article>
    </div>
  </div>
</section>