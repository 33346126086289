<div class="toast"
  [@fadeAnimation]="{
    value: this.animationState, params: {
      fadeIn: this.toastConfig.animation?.fadeIn || null, fadeOut: this.toastConfig.animation?.fadeOut || null
    }
  }"
  (@fadeAnimation.done)="onFadeFinished($event)"
  >
  <ids-alert [(show)]="open" icon variant="success">
    <div *ngIf="!isWindows && open" class="toast__container" aria-live="assertive" aria-atomic="true">
      <ng-container *ngTemplateOutlet="contentToast"></ng-container>
    </div>
    <div *ngIf="isWindows && open" class="toast__container" role="alert">
      <ng-container *ngTemplateOutlet="contentToast"></ng-container>
    </div>
  </ids-alert>
</div>

<ng-template #contentToast>
  {{ data.text }}
</ng-template>