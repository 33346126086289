<div class="bookmark-article" *ngIf="!homeHub">
  <button class="bookmark-article__button" role="button" [attr.aria-pressed]="isOn" (click)="onClick()" [attr.aria-label]="getLabel(articleTitle, isOn)">
    <ng-container *ngIf="!isOn">
      <svg class="bookmark-article__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <g clip-path="url(#clip0_2888_29)">
          <path d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3ZM17 18L12 15.82L7 18V6C7 5.45 7.45 5 8 5H16C16.55 5 17 5.45 17 6V18Z" class="not-selected" fill="#3B3B3B"/>
        </g>
        <defs>
          <clipPath id="clip0_2888_29">
          <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </ng-container>
    <ng-container *ngIf="isOn">
      <svg class="bookmark-article__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <g clip-path="url(#clip0_2138_9302)">
        <path d="M6.99988 3H16.9999C18.0999 3 18.9999 3.9 18.9999 5V21L11.9999 18L4.99988 21V5C4.99988 3.9 5.89988 3 6.99988 3Z" fill="#3B3B3B" class="selected"/>
        </g>
        <defs>
        <clipPath id="clip0_2138_9302">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </ng-container>
    <span *ngIf="type === 'long'" class="bookmark-article__label" aria-hidden="true">{{ getLabel('', isOn) }}</span>
  </button>
</div>