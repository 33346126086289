import { NgModule, ModuleWithProviders } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';

import { ToastComponent } from './toast.component';
import { defaultToastConfig, TOAST_CONFIG_TOKEN } from './toast-config';
import { CommonModule } from '@angular/common';
import { IdsAlertModule } from '@ids/angular';

@NgModule({
    imports: [
      OverlayModule,
      CommonModule,
      IdsAlertModule
    ],
    declarations: [ToastComponent]
})
export class ToastModule {
  public static forRoot(config = defaultToastConfig): ModuleWithProviders<any> {
    return {
      ngModule: ToastModule,
      providers: [
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...config },
        }
      ],
    };
  }
}
