import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

import { AnalyticsService } from "./../../../../core/services/analytics.service/analytics.service";
import { HelperService } from "./../../../../core/services/helpers.service/helpers.service";

@Component({
    selector: 'app-post-list',
    templateUrl: './post-list.component.html',
    styleUrls: ['./post-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PostListComponent implements OnInit {
  @Input() segment!: string;
  @Input() items: any[] = [];
  articles: any[] = [];
  analyticsComponent = 'MicrofrontHome'

  constructor (
    private readonly helper: HelperService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.articles = [...this.items];
  }
  
  getMostRecentURL(): string {
    const currentLang = this.helper.getLanguage();
    switch (currentLang) {
      case 'en-us':
        return `/${this.segment}/${currentLang}/see-all`;
      case 'es-es':
        return `/${this.segment}/${currentLang}/ver-todo`;
      default:
        return `/${this.segment}/ver-todos`;
    }
  }

  sendAnalyticsEvent(): void {
    this.analyticsService.trackEvent({
      type: 'LNK',
      category: 'Clique',
      action: `${this.segment}:${this.analyticsComponent}`,
      title: 'VerTodos',
      component: this.analyticsComponent,
      detail: `lnk:${this.segment}:${this.analyticsComponent}:VerTodos`,
    })
  }
}