import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentstackService } from '../../core/services/contentstack/contentstack.service';
import { AnalyticsService } from '../../core/services/analytics.service/analytics.service';

export type CategoryType = {
  uid: string;
  title: string;
  type?: 'bass' | 'deprecated'
};

export const DEFAULT_CATEGORY = { uid: String(''), title: 'Início' };


@Component({
  selector: 'app-menu-categories',
  templateUrl: 'menu-categories.component.html',
  styleUrls: ['menu-categories.component.scss']
})
export class MenuCategoriesComponent implements OnInit {
  @Input() segment: string = '';
  @Input() categorySelectedUid: string = DEFAULT_CATEGORY.uid || '';
  @Output() clickEvent = new EventEmitter<CategoryType>();
  
  categoryDefault = DEFAULT_CATEGORY;
  categories: CategoryType[] = [];
  clicked = false;

  constructor(
    private readonly service: ContentstackService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.service.categories(this.segment).subscribe(this.setCategories.bind(this));
  }

  isActive(uid: string) {
    return (this.categorySelectedUid === uid);
  }

  setCategory({event, category}: {event: Event | null, category: CategoryType | undefined}) {
    if (event && category) {
      event.preventDefault();
      this.clickEvent.emit(category);
      this.sendAnalyticsEvent();
      this.clicked = true;

      setTimeout(() => {
        this.clicked = false;
      }, 500);
    }

    if (category) {
      this.categorySelectedUid = category.uid;
    }
  }

  private setCategories(categories: CategoryType[]) {
    this.categories = categories.map(({uid, title, type}) => ({uid, title, type}));
    this.setCategory({event: null, category: this.findSelectedCategory()});
  }

  private sendAnalyticsEvent() {
    const category = this.findSelectedCategory();
    const component = 'MenuDeCategorias';
    this.analyticsService.trackEvent({
      action: `${this.segment}:${component}`,
      title: category.title,
      type: 'BTN',
      component,
      detail: `btn:${this.segment}:${component}:${category.title}`,
    });
  }

  private findSelectedCategory() {
    if (!this.categorySelectedUid || !this.categories.length) {
      return this.categoryDefault;
    }

    return this.categories.find(category => category.uid === this.categorySelectedUid) || this.categoryDefault;
  }
}
