import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContentstackService, LIMIT } from '../../core/services/contentstack/contentstack.service';
import { CategoryType, DEFAULT_CATEGORY } from './../../shared/menu-categories/menu-categories.component';

@Component({
  selector: 'blog-most-recent',
  templateUrl: './most-recent.component.html',
  styleUrls: ['./most-recent.component.scss']
})
export class MostRecentComponent implements OnInit {
  @Input() segment = '';
  @Input() category = DEFAULT_CATEGORY;
  isHome = false;

  public loading = false;
  public items: any[]= [];
  public loadMoreClicked = false;
  public pagination = {
    skip: 0,
    limit: LIMIT,
    count: 0
  };

  constructor(
    private readonly service: ContentstackService
  ) {}

  ngOnInit() {
    this.loadArticles();
  }

  @ViewChild('articleItem') set articleItem(_: Element) {
    if (!this.loading) {
      if (this.pagination.skip > this.pagination.limit) {
        const currentItem = (this.pagination.skip - this.pagination.limit) + 1;
        const itemToFocus = (
          document.querySelector('.articles__item:nth-child(' + currentItem + ') .mfe-card__link')
        ) as HTMLElement;
        itemToFocus.focus();
        window.scrollTo({ top: itemToFocus.offsetTop });
      }
    }
  }

  filterByCategory(category: CategoryType): void {
    this.category = category;
    this.pagination = { skip: 0, limit: LIMIT, count: 0 };
    this.items = [];
    this.loadArticles();
  }

  loadMore(): void {
    this.loadArticles(true);
  }

  showLoadMore(): boolean {
    return this.items.length < this.pagination.count;
  }

  private loadArticles(showMessage = false): void {
    this.loading = true;
    this.service.getContentType(this.segment);
    const category = this.category.uid ? this.category : DEFAULT_CATEGORY;

    this.service.articlesList(this.segment, this.pagination.skip, category).subscribe(
      (response: any[]) => {
        this.items.push(...response[0]);
        this.pagination.skip += LIMIT;
        this.pagination.count = response[1];
        this.loadMoreClicked = showMessage;

        setTimeout(() => {
          this.loadMoreClicked = false;
        }, 500);
      }
    ).add(() => this.loading = false);
  }
}
