<div class="skeleton container">
    <ngx-skeleton-loader count="1" class="skeleton__mobile-visible" [theme]="titleSkeleton" appearance="line"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" class="skeleton__mobile-visible" [theme]="subtitleSkeleton" appearance="line"></ngx-skeleton-loader>

    <div class="skeleton__featured">
        <div class="skeleton__featured--first">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="smallCardSkeleton"></ngx-skeleton-loader>
        </div>
        <div class="skeleton__featured--second">
            <ngx-skeleton-loader count="1" appearance="line" class="skeleton__desktop-visible" [theme]="smallCardSkeleton"></ngx-skeleton-loader>
        </div>
        <div class="skeleton__featured--third">
            <ngx-skeleton-loader count="1" appearance="line" class="skeleton__desktop-visible" [theme]="smallCardSkeleton"></ngx-skeleton-loader>
        </div>
    </div>

    <div class="skeleton__featured" [style]="defaultMargin">
        <div class="skeleton__featured--first">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="smallCardSkeleton"></ngx-skeleton-loader>
        </div>
        <div class="skeleton__featured--second">
            <ngx-skeleton-loader count="1" appearance="line" class="skeleton__desktop-visible" [theme]="smallCardSkeleton"></ngx-skeleton-loader>
        </div>
        <div class="skeleton__featured--third">
            <ngx-skeleton-loader count="1" appearance="line" class="skeleton__desktop-visible" [theme]="smallCardSkeleton"></ngx-skeleton-loader>
        </div>
    </div>

    <div class="skeleton__text-center">
        <ngx-skeleton-loader count="1" [theme]="buttonSkeleton" appearance="line"></ngx-skeleton-loader>
    </div>
</div>