import { Component, Input } from '@angular/core';
import { HelperService } from './../../../../core/services/helpers.service/helpers.service';
@Component({
  selector: 'app-featured-post',
  templateUrl: './featured-post.component.html',
  styleUrls: ['./featured-post.component.scss']
})
export class FeaturedPostComponent {
  @Input() article: any;
  @Input() segment: string;

  constructor(private readonly helpers: HelperService) {}

  public cropText(text: string, size: number = 200) {
    return this.helpers.cropText(text, size);
  }

  public get articleImage(): string {
    if (!this.article.page_properties || !this.article.page_properties.thumb_image) {
      return '';
    }
    return this.article.page_properties.thumb_image.path;
  }
}