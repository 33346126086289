import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { createCustomElement } from '@angular/elements';

import { SearchModule } from './search/search.module';
import { HomeModule } from './home/home.module';
import { BookmarkListModule } from './bookmark-list/bookmark-list.module';
import { BookmarkArticleModule } from './bookmark-article/bookmark-article.module';
import { MostRecentModule } from './most-recent/most-recent.module';
import { SearchListModule } from './search-list/search-list.module';
import { TagListModule } from './tag-list/tag-list.module';
import { LikertModule } from './likert/likert.module';

import { SearchComponent } from './search/search.component';
import { MostRecentComponent } from './most-recent/most-recent.component';
import { BookmarkListComponent } from './bookmark-list/bookmark-list.component';
import { SearchListComponent } from './search-list/search-list.component';
import { TagListComponent } from './tag-list/tag-list.component';
import { LikertComponent } from './likert/likert.component';
import { BookmarkArticleComponent } from './bookmark-article/bookmark-article.component';
import { HomeComponent } from './home/home.component';
import { RecentPostsComponent } from './recent-posts/recent-posts.component';
import { RecentPostsModule } from './recent-posts/recent-posts.module';
import { I18nModule } from '../core/i18n/i18n.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,

    // -- Components Modules
    HomeModule,
    SearchModule,
    BookmarkListModule,
    BookmarkArticleModule,
    MostRecentModule,
    RecentPostsModule,
    SearchListModule,
    TagListModule,
    LikertModule,

    // I18N Module
    I18nModule
  ]
})
export class GeneralModule implements DoBootstrap { 
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const elements: any[] = [
      [HomeComponent, 'blog-home'],
      [SearchComponent, 'blog-search'],
      [BookmarkListComponent, 'blog-bookmark-list'],
      [BookmarkArticleComponent, 'blog-bookmark-article'],
      [MostRecentComponent, 'blog-most-recent'],
      [SearchListComponent, 'blog-search-list'],
      [TagListComponent, 'blog-tag-list'],
      [LikertComponent, 'blog-likert'],
      [RecentPostsComponent, 'blog-recent-posts']
    ];
  
    for (const [component, tag] of elements) {
      const elem = createCustomElement(component, { injector: this.injector });
      customElements.define(tag, elem);
    }
  }
}
