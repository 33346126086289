import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AnalyticsService } from '../../core/services/analytics.service/analytics.service';
import { ToastData } from '../../core/toast/toast-config';
import { ToastService } from '../../core/toast/toast.service';
import { HelperService, languages } from '../../core/services/helpers.service/helpers.service';

@Component({
  selector: 'app-bookmark-article',
  templateUrl: './bookmark-article.component.html',
  styleUrls: ['./bookmark-article.component.scss']
})
export class BookmarkArticleComponent implements OnInit {
  @Input() articleUid: string = '';
  @Input() articleTitle: string = '';
  @Input() articleUrl: string = '';
  @Input() homeHub: boolean = false;

  @Input() type: 'short' | 'long' = 'short';
  @Input() segment: string = '';
  
  public isOn = false;
  public key = 'blog_bookmarks_';
  private bookmarks: any[] = [];
  private readonly toastData: ToastData = {
    duration: 5000,
    text: '',
    type: 'success',
    icon: ''
  };

  constructor(
    private helperService: HelperService,
    private toastService: ToastService,
    private analytics: AnalyticsService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.key += this.segment;
    this.bookmarks = this.getBookmarks();

    if (this.bookmarks.indexOf(this.articleUid) !== -1) {
      this.isOn = true;
    }
  }

  public getLabel(article = '', checked = false): string {
    const ariaLabel = checked ? 'BOOKMARK_ARTICLE.ARTICLE_SAVED' : 'BOOKMARK_ARTICLE.SAVE_TO_AFTER' ;
    return this.translate.instant(ariaLabel, { article });
  }

  public onClick(): void {
    this.bookmarks = this.getBookmarks();

    if (this.bookmarks.indexOf(this.articleUid) === -1) {
      this._saveBookmark();
    } else {
      this._removeBookmark();
    }
    this.toastService.show(this.toastData);
    this.sendAnalyticsEvent();

    window.localStorage.setItem(this.key, JSON.stringify(this.bookmarks));
  }

  public getBookmarks() {
    const bookmarks = [];
    if (localStorage.hasOwnProperty(this.key)) {
      bookmarks.push(...JSON.parse(String(localStorage.getItem(this.key))));
    }

    return bookmarks;
  }

  public sendAnalyticsEvent() {
    const component = 'BookMarkArticle';
    const title = this._formatTitleForAnalyticsEvent();
    const event = this.isOn ? 'ArtigoSalvo': 'ArtigoExcluido';
    this.analytics.trackEvent({
      type: 'BTN',
      component,
      title: this.articleTitle, 
      text: event,
      action: `${this.segment}:${component}`,
      detail: `btn:${this.segment}:${component}:${event}:${title}`
    });
  }

  private _saveBookmark() {
    this.bookmarks.push(this.articleUid);
    this.isOn = true;

    this.toastData.text = this.translate.instant('BOOKMARK_ARTICLE.TOAST_ADD');
  }

  private _removeBookmark() {
    this.bookmarks.splice(this.bookmarks.indexOf(this.articleUid), 1);
    this.isOn = false;
    this.toastData.text = this.translate.instant('BOOKMARK_ARTICLE.TOAST_REMOVE');
  }

  private _formatTitleForAnalyticsEvent(): string {
    if (this.articleUrl) {
      return this.helperService
        .clearSpecialChars(
          this.articleUrl
          .replace(new RegExp(`\\b(?:${languages.pt}|${languages.en}|${languages.es})\\b`, 'g'), '')
          .replace(this.segment, '')
          .replace(/(?:\/\/)/g, '')
          .replace(/(?<!^)\//g, '-')
        );
    }

    return this.helperService.clearSpecialChars(this.helperService.skewerCase(this.articleTitle));
  }
}
