<app-menu-categories [segment]="segment" [categorySelectedUid]="" (clickEvent)="filterByCategory($event)"></app-menu-categories>
<section class="most-recent">
  <div class="ids-container">
    <h2 class="articles__title">{{ isHome ? 'MOST_RECENT_TITLE' : 'ALL_ARTICLES'| translate }}</h2>
    <p class="hidden-sm hidden-md hidden-lg">{{ 'MOST_RECENT_MOBILE_TITLE' | translate }}</p>
    
    <ng-container>
      <div class="articles__list" *ngIf="items.length">
        <div #articleItem *ngFor="let item of items">
          <app-card [homeHub]="false" [authorBlogLink]="false" [article]="item" [segment]="segment" [analyticsComponent]="'MostRecentMicrofront'"></app-card>
        </div>
      </div>

      <div role="alert" class="screen-reader-feedback">
        <ng-container *ngIf="items.length && loadMoreClicked">
          <span aria-live="polite" aria-atomic="true">{{ 'SHOW_ITEMS_MESSAGE' | translate:{show: items.length, total: pagination.count} }}</span>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="loading">
      <app-most-recent-skeleton></app-most-recent-skeleton>
    </ng-container>

    <button idsMainButton
        *ngIf="showLoadMore()"
        [disabled]="loading"
        (click)="loadMore()" 
        class="load-more"
        role="button"
        aria-label="carregar mais artigos"
      >{{ 'LOAD_MORE' | translate }}</button>
  </div>
</section>