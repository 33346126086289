import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { PostListComponent } from './post-list.component';
import { I18nModule } from '../../../../core/i18n/i18n.module';
import { CardModule } from '../../../../shared/card/card.module';

@NgModule({
  declarations: [
    PostListComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    I18nModule,
    CardModule
  ],
  exports: [
    PostListComponent
  ],
  providers: [DatePipe],
  bootstrap: []
})
export class PostListModule { 
}